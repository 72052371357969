<template>
  <material-chart-card
    id="dailyLabResultChart"
    :class="{'admin':!$auth.user().client}"
    :data="lab_results_daily"
    :options="options"
    :responsive-options="responsiveOptions"
    :loading="loading.lab_results_daily && !lab_results_daily.labels.length"
    :style="cssVars"
    color="purple darken-4"
    type="Bar"
  >
    <h4
      class="text-h6 font-weight-light"
      v-text="title || computedTitle"
    />
    <div
      v-if="lab_results_daily.labels.length && !hideTrend"
      class="category d-inline-flex font-weight-light"
    >
      Average of {{ lab_results_daily.series[0]?.reduce((a,b) => a + b) / lab_results_daily.series[0]?.length | numeral('0') | separator }} positive results per day
    </div>

    <template #actions>
      <v-icon
        class="mr-2"
        small
      >
        mdi-clock-outline
      </v-icon>
      <span class="text-caption grey--text font-weight-light">{{ $t('updated') }} {{ updated | duration('humanize', true) }}</span>
      <v-spacer />
      <card-reload
        :loading="loading.lab_results_daily"
        @reload="fetchDailyResults({ no_cache: true, days: days })"
      />
    </template>
  </material-chart-card>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  components: {
    CardReload: () => import('@/components/CardReload.vue'),
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    days: {
      type: Number,
      default: 14,
    },
    hideTrend: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      options: {
        stackBars:true,
        chartPadding: {
          top: 20,
          right: 14,
          bottom: 14,
          left: this.$auth.check({ stats: 'performance' }) ? 6 : -20,
        },
        axisX: {
          showGrid: false,
          labelOffset: {
            xx: -10,
            y: 3,
          },
        },
        axisY: {
          showLabel: this.$auth.check({ stats: 'performance' }),
          labelOffset: {
            y: 5,
          },
          labelInterpolationFnc: (value) => value >= 1000 ? Vue.options.filters.number(value, '0.0a') : value,
        },
      },
      responsiveOptions: [
        ['screen and (max-width: 640px)', {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: (value) => value[0],
          },
        }],
      ],
    }
  },
  computed: {
    ...mapState('charts', ['loading', 'lab_results_daily']),
    updated () {
      return this.$moment(this.lab_results_daily.time).diff()
    },
    cssVars() {
      return {
        '--font-size': Math.min(100 / this.days, 10) + "pt",
        '--stroke-width': Math.min(70 / this.days, 8) + "%",
      }
    },
    computedTitle () {
      return this.$t('charts.lab_results_this_week')
    },
  },
  created () {
    if (!this.lab_results_daily.labels.length || this.$moment().diff(this.lab_results_daily.time, 'minutes') > 15) {
      this.fetchDailyResults({ no_cache: false, days: this.days })
    }
  },
  methods: {
    ...mapMutations("charts", ["setDailyChartData"]),
    ...mapActions("charts", ["fetchDailyResults"]),
  },
}
</script>

<style lang="scss">
  #dailyLabResultChart {
    .ct-label.ct-vertical {
      font-size: 8.5pt;
      line-height: 1.1;
    }
    .ct-label {
      font-size: var(--font-size);
    }
    .ct-bar {
      stroke-width: var(--stroke-width) !important;
    }
  }
  #dailyLabResultChart.admin {
    .ct-series-a {
      .ct-bar {
        stroke: rgb(200, 255, 200);
      }
    }
    .ct-series-b {
      .ct-bar {
        stroke: rgb(255, 200, 200);
        // stroke-dasharray: 3px 1px;
      }
    }
  }
</style>
